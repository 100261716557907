<template>
    <section class="overflow-auto custom-scroll" style="height:calc(100vh - 200px);">
      
        <!-- tabla de meses a pagar -->
        <div class="row mx-0 my-3">
          <span class="col-auto f-20 f-800">Pagos pendientes</span>
          <div class="col justify-content-lg-end d-middle-center">
              <p class="f-15">Sin controlar pagos</p>
              <el-switch v-model="pagoEstado" :active-value="0" :inactive-value="1" active-color="#00D9B5" inactive-color="#E6E6E6" class="mx-2" @change="cambiarEstadoPago"> </el-switch>
              <button class="btn-general btn btn-sm f-14" @click="ponerPazSalvo">Poner a paz y salvo</button>
              <div class="col-3">
                <el-input size="small" v-model="searchMesesAPagar" placeholder="Buscar mes"/>
              </div>
          </div>
        </div>
        <div class="row mx-0" v-loading="loading">
            <div class="col-12">
                <el-table :data="dataMesesAPagar" height="550" style="width: 100%">
                    <el-table-column sortable prop="mes_pagado" label="Mes de administración" min-width="50">
                      <template slot-scope="scope">
                            <div @click="scope.row.estado ? verInformacionPago(scope.row) : false ">
                                <p> {{ _.startCase(formatearFecha(scope.row.fecha_pago_mes,'MMMM YYYY')) }} </p>
                            </div>
                      </template>
                    </el-table-column>
                    <el-table-column sortable prop="valor" label="Valor" min-width="50">
                        <template slot-scope="scope">
                        <div class="">
                          {{ convertMoney(scope.row.valor) }}
                        </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="created_at" label="Fecha de pago" min-width="60">
                      <template slot-scope="scope">
                        {{ formatearFecha(scope.row.created_at,'DD MMM YYYY h:m a')}}
                      </template>
                    </el-table-column>
                    <el-table-column sortable prop="tipo" label="Tipo de pago" min-width="60">
                        <template slot-scope="scope">
                        <div>
                            <p v-if="[31,32,33].includes(scope.row.estado)">Pago en línea</p>
                            <p v-else-if="scope.row.estado == 12">Paz y salvo</p>
                            <p v-else>Efectivo</p>
                        </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="estado_pago" label="Estado" min-width="80">
                        <template slot-scope="scope">
                        <div @click="verInformacionPago(scope.row)">
                            <span v-show="scope.row.estado == 0" class="bg-naranja-fuerte br-4 px-2 py-1 f-14 text-white">Sin pagar</span>
                            <span v-show="scope.row.estado == 21" class="bg-pendiente br-4 px-2 py-1 f-14 text-muted">Sin verificar por administrador</span>
                            <span v-show="scope.row.estado == 31" class="bg-pendiente br-4 px-2 py-1 f-14 text-muted">Pendiente pago en línea</span>
                            <span v-show="scope.row.estado == 23" class="bg-rojo-claro br-4 px-2 py-1 f-14 text-white">Rechazado por administrador</span>
                            <span v-show="scope.row.estado == 33" class="bg-rojo-claro br-4 px-2 py-1 f-14 text-white">Rechazado pago en línea</span>
                            <span v-show="scope.row.estado == 11" class="bg-verde-fuerte br-4 px-2 py-1 f-14 text-white">Pago por administrador</span>
                            <span v-show="scope.row.estado == 22" class="bg-verde-fuerte br-4 px-2 py-1 f-14 text-white">Pago por residente</span>
                            <span v-show="scope.row.estado == 32" class="bg-verde-fuerte br-4 px-2 py-1 f-14 text-white">Pago en línea</span>
                            <span v-show="scope.row.estado == 12" class="bg-azulclaro br-4 px-2 py-1 f-14 text-center">Paz y salvo</span>
                        </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="estado_pago" min-width="50">
                        <template slot-scope="scope">
                        <div>
                            <div v-if="[21,31].includes(scope.row.estado)" class="btn boton-verificar rounded-pill br-20 px-3 f-14 text-center py-1 text-white" @click="verificarPago(scope.row.id_pago)">Verificar pago</div>
                            <div v-if="scope.row.estado == 0" class="btn boton-registrarpago br-20 px-3 f-14 text-center py-1 text-white" @click="registrarPago">Registrar pago</div>
                        </div>
                        </template>
                    </el-table-column>
                    <div class="row" slot="empty">
                        <div class="col-12 text-center">
                        <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120">
                        <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No hemos encontrado coincidencias con tu búsqueda</p>
                        </div>
                    </div>
                </el-table>
            </div>
        </div>
        <!-- tabla de historial de pagos -->
        <div class="row mx-0 my-3">
          <span class="col-auto f-20 f-800">Historial de pagos</span>
          <div class="col justify-content-lg-end d-middle-center">
              <div class="col-3">
                <el-input size="small" v-model="searchHistorial" placeholder="Buscar mes"/>
              </div>
          </div>
        </div>
        <div class="row mx-0" v-loading="loading">
            <div class="col-12">
                <el-table :data="dataHistorial" height="550" style="width: 100%">
                    <el-table-column sortable prop="fecha_pago_mes" label="Mes de administración" min-width="50">
                      <template slot-scope="scope">
                            <div>
                                <p> {{ _.startCase(formatearFecha(scope.row.fecha_pago_mes,'MMMM YYYY')) }} </p>
                            </div>
                      </template>
                    </el-table-column>
                    <el-table-column sortable prop="valor" label="Valor" min-width="50">
                        <template slot-scope="scope">
                        <div>
                            {{ convertMoney(scope.row.valor) }}
                        </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="created_at" label="Fecha de pago" min-width="60">
                      <template slot-scope="scope">
                        {{ formatearFecha(scope.row.created_at,'DD MMM YYYY h:m a')}}
                      </template>
                    </el-table-column>
                    <el-table-column sortable prop="tipo" label="Tipo de pago" min-width="60">
                        <template slot-scope="scope">
                        <div>
                            <p v-if="[31,32,33].includes(scope.row.estado)">Pago en línea</p>
                            <p v-else-if="scope.row.estado == 12">Paz y salvo</p>
                            <p v-else>Efectivo</p>
                        </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="estado" label="Estado" min-width="80">
                        <template slot-scope="scope">
                        <div @click="verInformacionPago(scope.row)">
                            <span v-show="scope.row.estado == 21" class="bg-pendiente br-4 px-2 py-1 f-14 text-muted">Sin verificar por administrador</span>
                            <span v-show="scope.row.estado == 31" class="bg-pendiente br-4 px-2 py-1 f-14 text-muted">Pendiente pago en línea</span>
                            <span v-show="scope.row.estado == 23" class="bg-rojo-claro br-4 px-2 py-1 f-14 text-white">Rechazado por administrador</span>
                            <span v-show="scope.row.estado == 33" class="bg-rojo-claro br-4 px-2 py-1 f-14 text-white">Rechazado pago en línea</span>
                            <span v-show="scope.row.estado == 11" class="bg-verde-fuerte br-4 px-2 py-1 f-14 text-white">Pago por administrador</span>
                            <span v-show="scope.row.estado == 22" class="bg-verde-fuerte br-4 px-2 py-1 f-14 text-white">Pago por residente</span>
                            <span v-show="scope.row.estado == 32" class="bg-verde-fuerte br-4 px-2 py-1 f-14 text-white">Pago en línea</span>
                            <span v-show="scope.row.estado == 12" class="bg-azulclaro br-4 px-2 py-1 f-14 text-center">Paz y salvo</span>
                        </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="estado" min-width="50">
                        <template slot-scope="scope">
                        <div>
                            <div v-if="[21,31].includes(scope.row.estado)" class="btn boton-verificar rounded-pill br-20 px-3 f-14 text-center py-1 text-white" @click="verificarPago(scope.row.id_pago)">Verificar pago</div>
                        </div>
                        </template>
                    </el-table-column>
                    <div class="row" slot="empty">
                        <div class="col-12 text-center">
                        <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120">
                        <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No hemos encontrado coincidencias con tu búsqueda</p>
                        </div>
                    </div>
                </el-table>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalAviso" titulo="Aviso" no-aceptar><p class="text-center">{{ mensaje }}</p></modal>
        <modal-registrar-pago ref="abrirModalRegistrarPago" @actualizar="actualizar"/>
        <modal-informacion-pago ref="abrirModalInfoPago" />
        <modal-paz-salvo ref="abrirModalPazSalvo" @actualizar="actualizar"/>
        <modal-historial-paz-salvo ref="abrirModalHistorialPazSalvo" />
    </section>
</template>
<script>
import moment from 'moment'
import Viviendas from '~/services/viviendas'
export default {
    components:{
        modalRegistrarPago: () => import('./partials/modalRegistrarPago'),
        modalInformacionPago: () => import('./partials/modalInformacionPago'),
        modalPazSalvo: () => import('./partials/modalPazSalvo'),
        modalHistorialPazSalvo: () => import('./partials/modalHistorialPazSalvo'),// no se está usando
    },
    data(){
        return{
            mensaje: '',
            loading: false,
            value2: false,
            mesesAPagar: [],
            pagoEstado: 0,
            historialDePagos: [],
            searchHistorial: null,
            searchMesesAPagar: null,
        }
    },
    created(){
      this.listarMesesAPagar()
      this.listarHistorialPagos()
    },
    computed: {
      dataHistorial(){
        return this.historialDePagos.filter(data => !this.searchHistorial || this.formatearFecha(data.fecha_pago_mes,'MMMM YYYY').includes(this.searchHistorial.toLowerCase()))
      },
      dataMesesAPagar(){
        return this.mesesAPagar.filter(data => !this.searchMesesAPagar || this.formatearFecha(data.fecha_pago_mes,'MMMM YYYY').includes(this.searchMesesAPagar.toLowerCase()))
      },
    },
    methods:{
      registrarPago(){
        this.$refs.abrirModalRegistrarPago.toggle()
      },
      verInformacionPago(pago){
        if (pago.estado == 12) {
          this.$refs.abrirModalHistorialPazSalvo.toggle(pago)
        }else{
          this.$refs.abrirModalInfoPago.toggle(pago)
        }
      },
      ponerPazSalvo(){
        this.$refs.abrirModalPazSalvo.toggle()
      },
      verificarPago(idPago){
        this.$router.push({ name: 'verificar.pago',params:{id_pago: idPago} })
      },
      setPagoEstado(estado){
        this.pagoEstado = estado
      },
      async actualizar(){
        await this.listarHistorialPagos()
        await this.listarMesesAPagar()

        let indicador =  this.mesesAPagar.length ? this.mesesAPagar.filter(el=>el.estado == 0) : []
        this.$store.dispatch('viviendas/actualizarNumMesesDebe',indicador.length) 
      },
      async listarHistorialPagos(){
        try {
          this.loading = true
          const {data} = await Viviendas.listarPagosVivienda(this.$route.params.id_vivienda)
          this.historialDePagos = data.data
          this.loading = false
        } catch (e) {
          this.errorCatch(e)
          this.loading = false
        }
      },
      async listarMesesAPagar(){
        try {
          const { data } = await Viviendas.listarMesesAPagar(this.$route.params.id_vivienda)
          if(!data.success){
            this.mensaje = data.mensaje
            this.$refs.modalAviso.toggle()
          }
          this.mesesAPagar = data.data.meses_a_pagar ?? []
          this.pagoEstado = data.data.vivienda_pago
          
        } catch (error) {
          this.errorCatch(error)
        }
      },
      async cambiarEstadoPago(){
        try {

          let params = {
            id_vivienda: this.$route.params.id_vivienda,
            estado: this.pagoEstado
          }

          const { data } = await Viviendas.noControlarPago(params)

          this.actualizar()
          this.notificacion('',`Pago de la vivienda ${params.estado ? 'habilitado' : 'inhabilitado'} correctamente `,'success')

        } catch (error) {
          this.errorCatch(error)
        }
      }
      
    }
}
</script>
<style lang="scss" scoped>
.viviendas-ver-pagos{
      .estado{
        &-pago{
          background: var(--color-db);
          color: var(--text-muted);
        }
        &-rechazado{
          background: #FFB2C2;
          color: #FFFFFF;
        }
        &-aprobado{
          background: #28D07B;
          color: #FFFFFF;
        }
      }
    }
    .boton{
      &-verificar{
        height: 28px;
        background: var(--color-general);
      }
      &-registrarpago{
        height: 28px;
        background: #000;
      }
    }
    .text-sin-datos{
      color: var(--sin-datos);
      max-width: 286px;
      
    }
</style>